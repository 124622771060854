// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getPromotionsCollections, getPOIList, postPromotionsFormData } from '@/services/basic-msg.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      getPromotionsCollections().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[6].options = res.data.collections.poi_types
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleChange (data, nextChangeProp, item, createDate) {
      if (item.prop === 'poi_type') {
        getPOIList(createDate.poi_type).then(res => {
          this.formData[0].createDateItem[7].options = res.data.pois
        })
      }
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      const params = {
        promotion: obj
      }
      // params.site.categories = params.site.categories.join(',')
      // Object.keys(params.site).forEach(res => {
      //   if (typeof params.site[res] === 'boolean') {
      //     if (params.site[res] === true) params.site[res] = '1'
      //     if (params.site[res] === false) params.site[res] = '0'
      //   }
      // })
      postPromotionsFormData(params).then(res => {
        if (res.status === 200 || res.status === 204) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'promotionList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
